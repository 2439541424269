@tailwind base;
@tailwind components;
@tailwind utilities;

.h-\[30px\] {
  height: 41px !important;
}

.w-\[30px\] {
  width: 41px !important;
}

.text-gray-200 {
  color: #e5e7eb;
  font-size: 1rem;
}

.bg-gray-950 {
  background-color: #22272e;
}

.bg-token-main-surface-secondary {
  background-color: #3a3838;
  color: #cecdcd;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

:root {
  //ChatGPT variables
  --gray-900: #2f2f2f;
  --gray-800: #2f2f2f;
  --gray-400: #b4b4b4;
}
