input {
  font-family: 'Georgia', 'Georgia', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  &.search {
    outline: none;
    color: #565555;
    background-color: rgb(242, 243, 247);
    text-transform: lowercase;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 32px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(133, 133, 133);
    border-radius: 25px;
  }
}
