.buttonContainer {
  box-shadow: 0 2px 24px 13px #6b1e1e1a;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  background-color: #6b1e1e;
  border-radius: 4px;
  z-index: 9;
  justify-content: space-between;
  display: flex;
}

.styledButton {
  padding: 5px 10px; /* Reduced padding */
  font-size: 1.2em;
  color: #f0f0ef;
  background-color: #6b1e1e;
  border: 0;
  cursor: pointer;
  width: 65px; /* add this */
  height: 65px; /* and this */
  display: flex; /* add this */
  align-items: center; /* add this */
  justify-content: center; /* add this */
}

.buttonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styledButton:hover {
  background-color: #a42c2c;
}
