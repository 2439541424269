.navbar {
  background-color: #6b1e1e;
  width: 62px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  justify-content: start;
  align-items: center;
  .menu-bars {
    position: absolute;
    top: 350px;
    margin-left: 5px;
    font-size: 3.5rem;
    background: none;
  }
  .nav-fix {
    position: absolute;
    top: 100px;
    justify-content: center;
    left: 0;
    .nav-text {
      a {
        font-size: 2.8rem;
        margin-left: 10px;
      }
      a:hover {
        background-color: #a42c2c;
      }
    }
  }

  .nav-menu {
    justify-content: center;
    position: fixed;
    left: -100%;
    top: 100px;
    &.active {
      left: 30px;
      width: 240px;
    }
  }

  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 75px;
    a {
      text-decoration: none;
      color: #f5f5f5;
      font-size: 1.8rem;
      width: 95%;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0px;
      border-radius: 4px;
      margin-left: 30px;
    }
    a:hover {
      background-color: #a42c2c;
    }
  }

  .nav-menu-items {
    width: 100%;
    padding-left: 8px;
  }

  .navbar-toggle {
    background-color: #4d5eff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  span {
    margin-left: 16px;
  }
}

.navbar-expand {
  width: 200px;
}

.navbar-shrink {
  width: 0px;
}

.main {
  margin-left: 92px;
  //transition: all 0.25s ease;
}

.main-expand {
  margin-left: 260px;
  //transition: all 0.25s ease;
}
