.btn {
  display: block;
  font-size: 20px;
  border-radius: 6px;
  font-family: Georgia;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  height: 50px;
  transition: all 0.3s;
  outline: transparent;
  span {
    padding-left: 10px;
    padding-right: 10px;
  }
  &:disabled {
    opacity: 0.5;
  }

  &.sm {
    height: 40px;
    font-size: 18px;
  }

  &.lg {
    height: 40px;
    font-size: 18px;
  }

  &.round {
    border-radius: 12px;
  }

  &.btn-primary {
    background-color: #6b1e1e;
    border-color: #6b1e1e;
    &.outline {
      border: 2px solid #6b1e1e;
      background-color: white;
      color: #6b1e1e;
      &:active {
        color: #fff;
        background-color: #6b1e1e;
        border-color: #6b1e1e;
      }
    }
    &:hover {
      box-shadow: 0 4px 20px 0px #00000024, 0 7px 10px -5px #ef53501f;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &.btn-secondary {
    background-color: #e6e6e5;
    border-color: #2d2d2d;
    &.outline {
      border: 2px solid #2d2d2d;
      background-color: white;
      color: #e6e6e5;
      &:active {
        color: #fff;
        background-color: #e6e6e5;
        border-color: #2d2d2d;
      }
    }
    &:hover {
      box-shadow: 0 4px 20px 0px #00000024, 0 7px 10px -5px #2d2d2d5e;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &.btn-warning {
    background-color: #ff9800;
    border-color: #ff9800;
    &.outline {
      border: 2px solid #ff9800;
      background-color: white;
      color: #ff9800;
    }
    &:hover {
      box-shadow: 0 4px 20px 0px #00000024, 0 7px 10px -5px #ff980054;
    }
  }

  &.btn-success {
    background-color: #4caf50;
    border-color: #4caf50;
    &.outline {
      border: 2px solid #4caf50;
      background-color: white;
      color: #4caf50;
    }
    &:hover {
      box-shadow: 0 4px 20px 0px #00000024, 0 7px 10px -5px #4caf506b;
    }
  }
}
