.response {
  overflow-x: scroll;
  white-space: pre-wrap;
}

.response::-webkit-scrollbar {
  display: none;
}

.response p {
  margin-bottom: 20px;
}

.response p {
  max-width: 100%;
}

.response ol {
  list-style: decimal;
  padding-left: 1.75rem;
  margin-top: 0;
}

.response pre {
  max-width: 300px;
  margin-bottom: 20px;
}

.response code {
  margin-bottom: 20px;
}

.response button {
  display: none !important;
}

.response table {
  font-size: 0.875em;
  line-height: 1.7142857;
  text-indent: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  table-layout: auto;
  width: 100%;
}

.response th:first-child {
  border-top-left-radius: 0.375rem;
}
.response th:last-child {
  border-right-width: 1px;
  border-top-right-radius: 0.375rem;
}

.response td:last-child {
  border-right-width: 1px;
  border-bottom-right-radius: 0.375rem;
}

.response td:first-child {
  border-bottom-left-radius: 0.375rem;
}

.response th {
  background-color: rgba(236, 236, 241, 0.2);
  text-align: left;
}

.response th,
.response td {
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding: 0.25rem 0.75rem;
}

@media screen and (min-width: 768px) {
  .response pre,
  .response p {
    max-width: 800px;
  }
}
