.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.75rem;
  border-radius: 0.25rem;

  color: #cdd9e5;
  background: #22272e;
}

.hljs-comment,
.hljs-punctuation {
  color: #768390;
}

.hljs-attr,
.hljs-attribute,
.hljs-meta,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id {
  color: #6cb6ff;
}

.hljs-variable,
.hljs-literal,
.hljs-number,
.hljs-doctag {
  color: #f69d50;
}

.hljs-params {
  color: #cdd9e5;
}

.hljs-function {
  color: #dcbdfb;
}

.hljs-class,
.hljs-tag,
.hljs-title,
.hljs-built_in {
  color: #8ddb8c;
}

.hljs-keyword,
.hljs-type,
.hljs-builtin-name,
.hljs-meta-keyword,
.hljs-template-tag,
.hljs-template-variable {
  color: #f47067;
}

.hljs-string,
.hljs-undefined {
  color: #96d0ff;
}

.hljs-regexp {
  color: #96d0ff;
}

.hljs-symbol {
  color: #6cb6ff;
}

.hljs-bullet {
  color: #f69d50;
}

.hljs-section {
  color: #6cb6ff;
  font-weight: bold;
}

.hljs-quote,
.hljs-name,
.hljs-selector-tag,
.hljs-selector-pseudo {
  color: #8ddb8c;
}

.hljs-emphasis {
  color: #f69d50;
  font-style: italic;
}

.hljs-strong {
  color: #f69d50;
  font-weight: bold;
}

.hljs-deletion {
  color: #ff938a;
  background-color: #78191b;
}

.hljs-addition {
  color: #8ddb8c;
  background-color: #113417;
}

.hljs-link {
  color: #96d0ff;
  font-style: underline;
}

single-image {
  min-width: 250px;
  width: 25%;
  display: inline-block;
  vertical-align: top;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px;
}

.overlay-container {
  position: relative;
}

.image {
  display: block;
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

.source {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  max-width: min(100% - 16px - 16px, 100px);
  padding: 4px 8px;
  color: #c4c7c5;
  background: rgba(60, 64, 67, 0.75);
  border-radius: 16px;
  text-decoration: none;
}

.source .favicon {
  height: 12px;
  width: 12px;
  vertical-align: text-bottom;
  margin-right: 4px;
  margin-bottom: 1px;
}

.source .label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gmat-caption {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  font-family: 'Google Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.1px;
}

.code-block .code-block-decoration.header {
  background-color: #37393b;
  border-radius: 16px 16px 0 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: #c4c7c5;
  padding: 8px 16px;
  min-height: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.code-block .code-block-decoration.footer {
  background-color: #2a2a2a;
  border-radius: 0 0 16px 16px;
  -webkit-padding-end: 0;
  -moz-padding-end: 0;
  padding-inline-end: 0;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-y: hidden;
  color: #c4c7c5;
  padding: 8px 16px;
  min-height: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.code-block pre {
  display: block;
  font-family: monospace;
  white-space-collapse: preserve;
  text-wrap: nowrap;
  margin: 0;
  max-width: none;
}
.code-editor {
  display: none;
}
.code-block__code {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.code-block pre .code-container {
  font: 400 0.875rem/1.125rem Google Sans Mono, monospace;
  color: #c4c7c5;
  background-color: #1b1b1b;
  display: block;
  overflow-x: auto;
  padding: 16px;
  direction: ltr;
  margin-bottom: 0;
}
